<template>
  <div>
    <div class="main-title">教师录单列表</div>
    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div class="list-head" data-v-69874ad0="">
          <p class="list-head-title" data-v-69874ad0="">温馨提示</p>
          <ul data-v-69874ad0="">
            <li data-v-69874ad0="">教师录单支持导出</li>
          </ul>
        </div>
      </div>
      <div class="padding-t-sm padding-b-sm">
        <span class="margin-l-sm fl-l">
          <el-select
            v-model="selectStatus"
            clearable
            placeholder="请选择状态"
            size="small"
          >
            <el-option
              v-for="item in status"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </span>
        <span class="margin-l-sm fl-l">
          <el-select
            v-model="course_type"
            clearable
            placeholder="请选择课程类型"
            size="small"
          >
            <el-option
              v-for="item in courseTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </span>
        <!-- <span class="margin-l-sm fl-l">
          <el-select
            v-model="class_type"
            clearable
            placeholder="请选择小节类型"
            size="small"
          >
            <el-option
              v-for="item in classTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </span> -->
        <span class="margin-l-sm fl-l" label="课程名称">
          <el-input
            v-model="courseName"
            placeholder="请输入课程名称"
          ></el-input>
        </span>
        <span class="margin-l-sm fl-l" label="创建人">
          <el-input v-model="creator" placeholder="请输入创建人"></el-input>
        </span>
        <span class="margin-l-sm fl-l">
          <el-date-picker
            v-model="datePick"
            :picker-options="pickerOptions"
            align="right"
            end-placeholder="结束日期"
            range-separator="至"
            size="small"
            start-placeholder="开始日期"
            type="daterange"
            unlink-panels
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </span>
        <span class="margin-l-sm fl-l">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
            >搜索</el-button
          >
          <!-- <el-button type="primary" size="small" @click="add">新增</el-button> -->
          <!-- <el-button type="primary" size="small" plain icon="el-icon-download" @click="exportExcel" :loading="downloadLoading">选择导出</el-button> -->
          <el-button
            v-if="role === 1"
            :loading="downloadLoading2"
            icon="el-icon-download"
            plain
            size="small"
            type="primary"
            @click="oneKeyExportExcel"
            >一键导出</el-button
          >
        </span>
        <span v-if="role === 1" class="margin-l-sm fl-r">
          <el-button
            size="small"
            type="primary"
            @click="verify(multipleSelection)"
            >一键审核</el-button
          >
        </span>
      </div>
      <!-- @selection-change="handleSelectionChange"
			row-key="id" -->
      <div class="table-content">
        <el-table
          ref="singleTable"
          v-loading="loading"
          :cell-class-name="rowItem"
          :cell-style="{ textAlign: 'center' }"
          :data="tableData"
          :header-cell-style="{ padding: 0 }"
          :row-key="getRowKey"
          highlight-current-row
          style="width: 100%; background: #fff"
          @current-change="handleCurrentChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            v-if="role === 1"
            :reserve-selection="true"
            align="center"
            type="selection"
            width="90"
          >
          </el-table-column>
          <el-table-column align="center" label="ID" property="id" width="50">
          </el-table-column>
          <el-table-column label="课程名称" property="course_name">
          </el-table-column>
          <el-table-column label="章节名称" property="chapter_name">
          </el-table-column>
          <!--          <el-table-column label="小节名称" property="class_name" >-->
          <!--            <template slot-scope="scope">-->
          <!--              <span v-if="scope.row.class_type == 2">-->
          <!--                {{ scope.row.class_name }}-->
          <!--              </span>-->
          <!--              <span v-else>-->
          <!--                {{ scope.row.file_name }}-->
          <!--              </span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column label="图片" property="pics" width="100">
            <template slot-scope="scope">
              <span>
                <el-image
                  :preview-src-list="scope.row.pics"
                  :src="scope.row.pics[0]"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </span>
            </template>
          </el-table-column>
          <!--          <el-table-column label="小节类型" property="class_type"  align="center">-->
          <!--            <template slot-scope="scope">-->
          <!--              <span>-->
          <!--                <el-tag size="mini" v-if="scope.row.class_type === 1">课件</el-tag>-->
          <!--                <el-tag size="mini" type="danger" v-else>课时</el-tag>-->
          <!--              </span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column align="center" label="课程类型">
            <template slot-scope="scope">
              <span>
                <el-tag v-if="scope.row.course_type === 1" size="mini"
                  >付费直播</el-tag
                >
                <el-tag
                  v-else-if="scope.row.course_type === 2"
                  size="mini"
                  type="success"
                  >免费直播</el-tag
                >
                <el-tag
                  v-else-if="scope.row.course_type === 3"
                  size="mini"
                  type="warning"
                  >体验直播</el-tag
                >
                <el-tag v-else size="mini" type="danger">录播课</el-tag>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="课时"
            property="class_quantity"
          >
          </el-table-column>

          <el-table-column align="center" label="课件" property="file_quantity">
          </el-table-column>

          <el-table-column
            align="center"
            label="小节数量"
            property="class_nums"
          >
          </el-table-column>

          <el-table-column align="center" label="资料数量" property="file_nums">
          </el-table-column>
          <el-table-column align="center" label="视频时长">
            <template slot-scope="scope">
              {{ formatTime(scope.row.workload) }}
            </template>
          </el-table-column>

          <el-table-column label="备注" property="desc"></el-table-column>
          <el-table-column
            align="center"
            label="创建时间"
            property="created_at"
          >
          </el-table-column>
          <!--          <el-table-column-->
          <!--            property="updated_at"-->
          <!--            label="更新时间"-->
          <!--            align="center"-->
          <!--            width="160"-->
          <!--          >-->
          <!--          </el-table-column>-->
          <el-table-column
            align="center"
            label="创建人"
            property="creator"
            width="120"
          >
          </el-table-column>
          <el-table-column align="center" label="状态" width="80">
            <template slot-scope="scope">
              <span>
                <el-tag v-if="scope.row.status === 1" type="info"
                  >未核实</el-tag
                >
                <el-tag v-else type="success">已核实</el-tag>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            fixed="right"
            label="操作"
            width="180"
          >
            <template slot-scope="scope">
              <span style="margin-right: 1rem">
                <el-link
                  slot="reference"
                  type="primary"
                  @click="edit(scope.row.id)"
                  >编辑</el-link
                >
              </span>
              <span
                v-if="
                  scope.row.can_verify === true &&
                  scope.row.status === 1 &&
                  role == 1
                "
                style="margin-right: 1rem"
              >
                <!-- <el-link
                  slot="reference"
                  type="primary"
                 @click="verify(scope.row.id)"
                  >核实</el-link> -->

                <el-popconfirm
                  title="确定核实吗？"
                  @confirm="verify([scope.row.id])"
                >
                  <el-link slot="reference" type="primary">核实</el-link>
                </el-popconfirm>
              </span>
              <span>
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="删除后将不可恢复，确定删除吗？"
                  @confirm="deleted(scope.row.id)"
                >
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-l">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 20]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            small
            @size-change="setPageSize"
            @current-change="setPage"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { config } from "../../../js/config";

export default {
  name: "list",
  data() {
    return {
      imageUrl: config.imageUrl,
      page: 1,
      jurisdiction: 0,
      pageSize: 10,
      total: 0,
      loading: false,
      downloadLoading: false,
      downloadLoading2: false,
      name: "",
      tableData: [],
      currentRow: null,
      selectStatus: "",
      // class_type: "",
      course_type: "",
      creator: "",
      datePick: null,
      courseName: "",
      status: [
        {
          label: "未核实",
          value: 1,
        },
        {
          label: "已核实",
          value: 2,
        },
      ],
      courseTypes: [
        {
          label: "付费直播",
          value: 1,
        },
        {
          label: "免费直播",
          value: 2,
        },
        {
          label: "体验直播",
          value: 3,
        },
        {
          label: "录播课",
          value: 4,
        },
      ],
      classTypes: [
        {
          label: "课件",
          value: 1,
        },
        {
          label: "课时",
          value: 2,
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      multipleSelection: [],
    };
  },
  created() {
    this.getList();
    console.log(this);
  },
  computed: {
    ...mapState({
      role: (state) => state.user.info.role,
    }), //方法2
  },
  methods: {
    ...mapActions("teacherRecord", [
      "getTeacherRecordList",
      "verifyTeacherRecord",
      "delTeacherRecord",
      "exportTeacherRecord",
    ]),
    getRowKey(row) {
      return row.id;
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds =
        remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
      return `${formattedMinutes}:${formattedSeconds}`;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    headeCellStyle({ row, rowIndex }) {
      return "{padding:0}";
    },
    rowItem() {
      return "row-item";
    },
    search() {
      this.tableData = [];
      this.page = 1;
      this.getList();
    },
    // 获得列表
    async getList() {
      this.loading = true;
      var vm = this;
      let postData = {
        page: vm.page,
        pageSize: vm.pageSize,
        courseName: vm.courseName,
      };
      if (vm.creator !== "") {
        postData.creator = vm.creator;
      }
      if (vm.selectStatus !== "") {
        postData.status = vm.selectStatus;
      }
      if (vm.course_type !== "") {
        postData.courseType = vm.course_type;
      }
      // if (vm.class_type !== "") {
      //   postData.classType = vm.class_type;
      // }
      if (vm.datePick !== null) {
        postData.datePick = vm.datePick;
      }
      const { res_info, data } = await this.getTeacherRecordList(postData);
      if (res_info !== "ok") return;
      this.total = data.total;
      data.list.forEach((item) => {
        for (let i = 0; i < item["pics"].length; i++) {
          if (!item["pics"][i]) {
            item["pics"][i] = "";
          } else {
            item["pics"][i] = this.imageUrl + item["pics"][i];
          }
        }
      });
      this.tableData = data.list;
      this.loading = false;
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    // 新增
    add() {
      this.$router.push("/teacherRecord/add");
    },
    // 编辑
    edit(val) {
      this.$router.push("/teacherRecord/edit/" + val);
    },
    // 核实
    async verify(val) {
      await this.verifyTeacherRecord(val)
        .then((res) => {
          if (res.ret === 0) {
            this.$message.success("核实成功!");
            this.getList();
          }
        })
        .catch((err) => {});
    },
    // 删除
    async deleted(val) {
      await this.delTeacherRecord(val)
        .then((res) => {
          if (res.ret === 0) {
            this.$message.success("删除成功!");
            this.getList();
          }
        })
        .catch((err) => {});
    },
    // 多选
    // toggleSelection(rows) {
    //         if (rows) {
    //           rows.forEach(row => {
    // 				this.$refs.multipleTable.toggleRowSelection(row)
    //           });
    //         } else {
    // 			this.$refs.multipleTable.clearSelection()
    //         }
    // },
    // handleSelectionChange(val) {
    // 	this.multipleSelection = val
    // },
    // // 导出excel
    // exportExcel(){
    //   this.$confirm('确定导出文件?', '提示', {
    //           confirmButtonText: '确定',
    //           cancelButtonText: '取消',
    //           type: 'warning'
    //         }).then(() => {
    // 		  if(typeof this.multipleSelection === 'undefined'){
    // 			  this.$message.info('请选择需要导出的数据')
    // 			  return
    // 		  }
    //           this.export2Excel()
    //         }).catch(() => {

    // 	})
    // },
    // export2Excel(){
    // 	this.downloadLoading = true
    // 	import('@/utils/Export2Excel').then(excel => {
    // 		const tHeader = ['id', '名称', '类型', '数量或课时', '上课类型','备注','创建时间','更新时间','创建人','状态']
    // 		const filterVal = ['id', 'name', 'type', 'workload', 'class_type','desc','created_at','updated_at','creator','status']
    // 		const data = this.formatJson(filterVal,this.multipleSelection)
    // 		excel.export_json_to_excel({
    // 		  header: tHeader,
    // 		  data,
    // 		  filename: '教师录单-' + tools.getFormatDate()
    // 		})
    // 		this.downloadLoading = false
    // 	})
    // },
    // formatJson(filterVal,jsonData) {
    //       return jsonData.map(v => filterVal.map(j => {
    //           if(j === 'type'){
    // 			  if(v[j] === 1){
    // 				  v[j] = '课件'
    // 			  }else{
    // 				  v[j] = '课时'
    // 			  }
    // 		  }
    // 		  if(j === 'class_type'){
    // 			  if(v[j] === 1){
    // 				  v[j] = '付费直播'
    // 			  }else if(v[j] === 2){
    // 				   v[j] = '免费直播'
    // 			  }else if(v[j] === 3){
    // 				  v[j] = '体验直播'
    // 			  }else{
    // 				  v[j] = '录播课'
    // 			  }
    // 		  }
    // 		  if(j === 'status'){
    // 			  if(v[j] === 1){
    // 				  v[j] = '未核实'
    // 			  }else{
    // 				  v[j] = '已核实'
    // 			  }
    // 		  }
    // 		  return v[j]
    //   }))
    // },
    // 一键导出 直接导出所有符合条件的数据
    async oneKeyExportExcel() {
      this.downloadLoading2 = true;
      var vm = this;
      let postData = {
        page: vm.page,
        pageSize: vm.pageSize,
        courseName: vm.courseName,
      };
      if (vm.creator !== "") {
        postData.creator = vm.creator;
      }
      if (vm.selectStatus !== "") {
        postData.status = vm.selectStatus;
      }
      if (vm.course_type !== "") {
        postData.courseType = vm.course_type;
      }
      // if (vm.class_type !== "") {
      //   postData.classType = vm.class_type;
      // }
      if (vm.datePick !== null) {
        postData.datePick = vm.datePick;
      }
      if (this.creator !== "") {
        postData.creator = this.creator;
      }
      if (this.selectStatus !== "") {
        postData.status = this.selectStatus;
      }
      if (this.selectClassType !== "") {
        postData.classType = this.selectClassType;
      }
      if (this.datePick !== null) {
        postData.datePick = this.datePick;
      }
      const { res_info, data } = await this.exportTeacherRecord(postData);
      if (res_info !== "ok") return;
      this.$message.success("添加到队列中");
      // this.$confirm("导出数据已完成,是否下载?", "温馨提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "success",
      // })
      //   .then(() => {
      //     this.$message.success('添加到队列中')
      //     // 下载xlsx
      //     // var blob =
      //     //   "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      //     //   data;
      //     // var fileName = "教师录单" + tools.getFormatDate() + ".xlsx";
      //     // tools.downloadFileByBase64(blob, fileName);
      //   })
      //   .catch(() => {
      //     this.$message.info("已取消下载!");
      //   });
      this.downloadLoading2 = false;
    },
  },
};
</script>

<style></style>